<template>
  <div>
    <Table
      size="small"
      max-height="420"
      stripe
      :data="list"
      :columns="tableColumns"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}} {{variableTitle}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { getDeviceTaskitemPage } from '@/api/msp/device'
import ImgLightBox from '@/components/common/ImgLightBox'
export default {
  components: {
    ImgLightBox
  },
  data () {
    return {
      visiblePreview: false,
      fileName: '',
      variableTitle: '',
      query: {
        deviceId: null,
        pageNumber: 1,
        pageSize: 10
      },
      list: [],
      total: 0,
      tableColumns: [
        { title: '任务类型', align: 'center', key: 'typeName' },
        { title: '问题描述', align: 'center', key: 'remark' },
        { title: '作业日期', align: 'center', key: 'actionDate' },
        {
          title: '供应商',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        { title: '状态', align: 'center', key: 'statusName' },
        {
          title: '照片反馈',
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemAttachs && params.row.taskitemAttachs.length) {
              return h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    const postData = {
                      startIndex: 0,
                      imgList: params.row.taskitemAttachs.map(x => x.fileKey)
                    }
                    this.fileName = `${params.row.domainName} ${params.row.code}`
                    this.variableTitle = params.row.finishTime ? `（完成时间：${params.row.finishTime}）` : ''
                    this.curShowFileArray = params.row.taskitemAttachs
                    this.$refs.imgLightBox.init(postData)
                    this.visiblePreview = true
                  }
                }
              }, '查看照片')
            }
          }
        }
      ]
    }
  },
  computed: {
    deviceId () {
      return this.$store.state.equipment.detailId
    }
  },
  created () {
    if (this.deviceId) {
      this.initData()
    }
  },
  methods: {
    initData () {
      this.query.deviceId = this.deviceId
      getDeviceTaskitemPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handlePageChanged (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    }
  }
}
</script>
