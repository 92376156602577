<template>
  <div>
    <div class="m-b-10">
      <!-- <light-box-attribute-component :resourceModel="resourceModel"></light-box-attribute-component> -->
      <div class="m-b-10">
        <h3>资产属性</h3>
        <Row class="m-b-5">
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>设备编号：</label>
            <span>{{deviceModel.code}}</span>
          </i-col>
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>所属资产：</label>
            <span>{{deviceModel.assetName}}</span>
          </i-col>
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>站点名称：</label>
            <span>{{deviceModel.stationName }}</span>
          </i-col>
        </Row>
        <Row class="m-b-5">
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>所在位置：</label>
            <span>{{deviceModel.position}}</span>
          </i-col>
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>设备规格：</label>
            <span>{{deviceModel.withWithHeight}}</span>
          </i-col>
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>设备类型：</label>
            <span>{{deviceModel.devicemodelName}}</span>
          </i-col>

        </Row>
        <Row class="m-b-5">
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>建档时间：</label>
            <span> {{deviceModel.createTime}}</span>
          </i-col>
          <i-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="8"
          >
            <label>设备状态：</label>
            <span>{{deviceModel.enabledName}}</span>
          </i-col>
        </Row>
      </div>

    </div>

    <Tabs
      v-model="tabValue"
      size="small"
    >
      <TabPane
        name="1"
        label="上报信息"
      >
        <report-list></report-list>
      </TabPane>
      <TabPane
        name="2"
        label="任务列表"
      >
        <device-task-list></device-task-list>
      </TabPane>
      <TabPane
        name="3"
        label="变更记录"
      >
        <device-history></device-history>
      </TabPane>
      <TabPane
        name="4"
        label="位置信息"
      >
        <res-svg></res-svg>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import ReportList from './ReportList'
import DeviceTaskList from './DeviceTaskList.vue'
import DeviceHistory from './DeviceHistory.vue'
// import EditDevice from './EditDevice'
import ResSvg from './ResSvg'
/** new api */
import { getDevice } from '@/api/rim/device'

export default {
  name: 'LightBoxInfoComponent',
  components: {
    // LightBoxAttributeComponent,
    ReportList,
    DeviceTaskList,
    DeviceHistory,
    // EditDevice,
    ResSvg
  },
  // props: {
  //   tab: {
  //     type: String,
  //     default: '1'
  //   }
  // },
  computed: {
    deviceId () {
      return this.$store.state.equipment.detailId
    },
    tabValue: {
      get () {
        return this.$store.state.equipment.showTab
      },
      set (val) {
        this.$store.commit('set_show_tab', val)
      }
    }
    // showTechParams () {
    //   return this.$store.state.equipment.showTechParams
    // }
  },
  data () {
    return {
      deviceModel: {}
    }
  },
  // created () {
  //   if (this.detailId) {
  //     this.getResourceInfo()
  //   }
  // },
  methods: {
    getDeviceInfo () {
      getDevice({ deviceId: this.deviceId }).then(res => {
        if (res && !res.errcode) {
          this.deviceModel = res
        } else {
          this.deviceModel = {}
        }
      })
    }
  },
  watch: {
    deviceId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.getDeviceInfo()
        }
      }
    }
  }
}
</script>
