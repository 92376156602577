<template>
  <div>
    <dong-jiao-component  v-if="publisherId===29" ref="dongjiao"/>
    <resource-svg-component v-else ref="resourceSvg" />
  </div>
</template>

<script>
import ResourceSvgComponent from '@/components/svg/SvgResource'
import DongJiaoComponent from '@/components/svg/Dongjiao'

// import { getDeviceSvg } from '@/api/product/stationgraph'
/** new api */
import { getDeviceSvg } from '@/api/rim/stationgraph'
export default {
  components: {
    ResourceSvgComponent, DongJiaoComponent
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId
    }
  },
  computed: {
    deviceId () {
      return this.$store.state.equipment.detailId
    }
  },
  // created () {
  //   if (this.detailId) {
  //     this.getResourceSvg()
  //   }
  // },
  methods: {
    getDeviceSvgData () {
      getDeviceSvg({ deviceId: this.deviceId }).then(res => {
        if (this.publisherId === 29) {
          this.$refs.dongjiao.showLocation(this.deviceId)
        } else {
          this.$refs.resourceSvg.bindLightBoxData(res, this.deviceId)
        }
      })
    }
  },
  watch: {
    deviceId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.getDeviceSvgData()
        }
      }
    }
  }
}
</script>
